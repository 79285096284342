import React, { useState } from "react"
import LogoTrstudio from '../images/logos/trstudio.svg'
import LogoFb from '../images/logos/fb.svg'
import LogoIn from '../images/logos/insta.svg'
import LogoCh from '../images/logos/ch.svg'

const Footer = () => {
    return(
        <footer className="footer">
            <div className="row mx-0">
                <div className="col-md-6">
                    <img src={LogoTrstudio} alt="trstudio" className="tr-logo" />
                </div>
                <div className="col-md-6 justify-content-md-end justify-content-center my-3 my-md-0 d-flex">
                    <a href="" target="_blank">
                        <img src={LogoFb} alt="Facebook" className="px-3"/>
                    </a>
                    <a href="https://www.instagram.com/tr_studiomx" target="_blank">
                        <img src={LogoIn} alt="insta" className=""/>
                    </a>
                </div>
            </div>
            <div className="row mx-0 justify-content-between">
                <div className="col-md-4 mt-3 text-center text-md-left">
                    <p className="text-white text-center text-md-left texto-mobile">
                        Leibnitz 14, interior 605, col. Anzures <br />
                        Miguel Hidalgo, C.P: 11590, CDMX
                    </p>
                    {/* <a href="tel:+5555555" className="text-white text-center text-md-left mx-auto mx-md-0 texto-mobile" target="_blank">
                        5 55 55 55
                    </a> */}
                    <a href="#" className="text-white text-center text-md-left mx-auto mx-md-0 texto-mobile" target="_blank">
                        Aviso de privacidad
                    </a>
                </div>
                <div className="col-md-4 mt-3 d-flex align-items-end justify-content-center">
                    <p className="text-white text-center mb-0 texto-mobile">
                        © COPYRIGHT 2020 TODOS LOS DERECHOS RESERVADOS
                    </p>
                </div>
                <div className="col-md-4 mt-3 mb-5 my-md-3 d-flex align-items-end justify-content-md-end justify-content-center text-center text-md-right">
                    <a href="" target="_blank">
                        <img src={LogoCh} alt="linkedin" className="logo-concept"/>
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer