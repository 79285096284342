import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import LogoTrstudio from '../images/logos/trstudio.svg'
import LogoTrstudioMobile from '../images/logos/trstudiomobile.svg'
import Fade from 'react-reveal/Fade';


const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    

    return(
        <header className="position-absolute w-100 header">
            <Navbar expand="md" light>
                <Fade down duration={2000}>
                    <NavbarBrand href="/" className="mr-auto">
                        <img src={LogoTrstudio} alt="Logo Trstudio" className="logo-header"/>
                        <img src={LogoTrstudioMobile} alt="Logo Trstudio" className="logo-header-mobile"/>
                    </NavbarBrand>
                </Fade>
                <NavbarToggler onClick={toggle} />
                <Collapse  isOpen={isOpen} navbar>
                    <Nav className="ml-md-auto" navbar>
                        <Fade cascade duration={2000} delay={100}>
                            <NavItem>
                                <NavLink href="#nosotros">
                                    NOSOTROS
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#servicios">
                                    SERVICIOS
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#nuestrosproyectos">
                                    PROYECTOS
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#contacto">
                                    CONTACTO
                                </NavLink>
                            </NavItem>
                        </Fade>
                        
                    </Nav>
                </Collapse>
            </Navbar>
                    <div className="sticky-button ">
                    <div className="button-decorator"></div>
                        <a href="#contacto" className="meinteresa">
                        ME INTERESA
                        </a>
                        
                        
                    </div>


            
        </header>
    );
}

Header.propTypes = {
siteTitle: PropTypes.string,
}

Header.defaultProps = {
siteTitle: ``,
}

export default Header
